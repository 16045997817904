import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const id = Math.floor(Math.random() * 1000);
  return (
    <Svg viewBox="0 0 33 30" fill="none" {...props}>
      <path d="M26.2009 0H5.62009L0 10.3755H5.37118L8.26638 4.82096H23.6594L26.2009 0Z" fill="white" />
      <path
        d="M32.358 10.703L26.8035 0.393005L24.1441 5.21397L27.1572 10.703L19.4934 24.7729L22.0873 29.6594L32.358 10.703Z"
        fill="white"
      />
      <path d="M10.3231 30L0 11.0961H5.37118L12.9825 25.1004H18.9563L21.6157 30H10.3231Z" fill="white" />
      <path
        d="M23.5152 5.43665L26.2662 10.572L25.3623 12.1965C25.3623 12.1965 25.1265 11.4759 24.7597 11.0305C24.406 10.572 23.5021 10.2445 23.5021 10.2445H11.7641C11.109 9.01306 10.8732 8.75106 10.4409 7.57202C10.0217 6.40608 11.9344 5.55455 11.9344 5.55455L23.5152 5.43665Z"
        fill="white"
      />
      <path
        d="M19.0612 24.2882H13.3232L12.4717 22.6376C12.4717 22.6376 13.1922 22.7555 13.7424 22.6376C14.2926 22.5197 14.9477 21.9695 14.9477 21.9695L20.987 10.9127C22.3101 10.9127 22.7162 10.821 23.8691 11.0044C25.0219 11.2009 24.7337 13.4673 24.7337 13.4673L19.0612 24.2882Z"
        fill="white"
      />
      <path
        d="M5.92139 10.5852L8.73798 5.5022H10.6768C10.6768 5.5022 10.1921 5.92141 10.0087 6.49783C9.83842 7.07425 9.82532 7.82097 9.82532 7.82097L15.7467 19.0743C15.1048 20.345 14.9214 20.6201 14.2009 21.6288C13.4934 22.6376 11.8166 21.262 11.8166 21.262L5.92139 10.5852Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
