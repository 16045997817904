import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const id = Math.floor(Math.random() * 1000);
  return (
    <Svg viewBox="0 0 34 34" fill="none" {...props}>
      <circle cx="17" cy="17" r="17" fill="black" />
      <path d="M24.4333 6.927H9.16844L5 14.6226H8.98383L11.1312 10.5027H22.5483L24.4333 6.927Z" fill="white" />
      <path
        d="M28.9998 14.8655L24.8799 7.21851L22.9074 10.7942L25.1423 14.8655L19.458 25.3012L21.3819 28.9255L28.9998 14.8655Z"
        fill="white"
      />
      <path d="M12.6567 29.1781L5 15.157H8.98383L14.6292 25.5441H19.06L21.0325 29.1781H12.6567Z" fill="white" />
      <path
        d="M22.4415 10.9595L24.482 14.7684L23.8115 15.9733C23.8115 15.9733 23.6366 15.4388 23.3646 15.1085C23.1022 14.7684 22.4318 14.5255 22.4318 14.5255H13.7256C13.2398 13.6121 13.0649 13.4178 12.7443 12.5433C12.4333 11.6785 13.852 11.0469 13.852 11.0469L22.4415 10.9595Z"
        fill="white"
      />
      <path
        d="M19.138 24.9417H14.8821L14.2505 23.7174C14.2505 23.7174 14.7849 23.8049 15.193 23.7174C15.6011 23.63 16.0869 23.2219 16.0869 23.2219L20.5663 15.021C21.5477 15.021 21.8489 14.953 22.704 15.089C23.559 15.2348 23.3453 16.9158 23.3453 16.9158L19.138 24.9417Z"
        fill="white"
      />
      <path
        d="M9.3916 14.778L11.4807 11.0079H12.9187C12.9187 11.0079 12.5592 11.3189 12.4232 11.7464C12.2969 12.1739 12.2872 12.7278 12.2872 12.7278L16.6791 21.0744C16.203 22.0169 16.0669 22.221 15.5325 22.9691C15.0078 23.7173 13.7641 22.6971 13.7641 22.6971L9.3916 14.778Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
