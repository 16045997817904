import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const ICAKE = {
  [ChainId.BSC]: '0xc6D8772E332C34ae7671317c6BDFF2B5B161d19e',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.CYBA]: '0x454E1314d26dcd6A8CB17b388078929593B3426a',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_VAULT = {
  [ChainId.BSC]: '0x40ad72a13Ac028bc39721a569490eDb8401CB0fc',
  [ChainId.BSC_TESTNET]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.CYBA]: '0x4B28041fdF10a5589c2088B0c72057820f50BEB7',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x18D7d102e1098C8e29DEE57524c3a37b71e81f90',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.CYBA]: '0x33EF2402D616E693267C27b975f09df332FaDAe3',
} as const satisfies ContractAddresses<SupportedChainId>
