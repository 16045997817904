import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | CYBASWAP',
  defaultTitle: 'CYBASWAP',
  description:
    'Cheaper and faster than Uniswap? Discover CYBASWAP, the leading DEX on CYBA Smart Chain (CYBA) with the best farms in DeFi and a lottery for CSWAP.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@CYBASWAP',
    site: '@CYBASWAP',
  },
  openGraph: {
    title: 'CYBASWAP - A next evolution DeFi exchange on CYBA Smart Chain (CYBA)',
    description:
      'CYBASWAP part of CYBA Network Ecosystem, earn CSWAP through farming, staking or lottery,  swap your tokens with security and confidence.',
    // images: [{ url: process.env.NEXT_PUBLIC_URL + '/images/hero.png?v=1.0' }],
  },
}
