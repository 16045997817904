import { vars } from "@pancakeswap/ui/css/vars.css";
import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { Box, Flex, Grid } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledLink,
  StyledLinkFlex,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import {
  ArrowForwardIcon,
  LogoWithTextIcon,
  LogoFooter,
  LogoIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  DiscordIcon,
  MediumIcon,
  LinkedInIcon,
  RedditIcon,
  GithubIcon,
  ZedscanIcon,
  ZedbridgeIcon,
  ZedStakeIcon,
  ZedtokenIcon,
  ZeddropIcon,
  TelegramIcon,
} from "../Svg";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";
import { SkeletonV2 } from "../Skeleton";
import { Text } from "../Text";
import Image from "next/image";
import NeoBankIcon from "../Svg/Icons/NeoBankIcon";
import ZedXionIcon from "../Svg/Icons/ZedXionIcon";
import ZedCexIcon from "../Svg/Icons/ZedCexIcon";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  return (
    <StyledFooter
      // data-theme="light"
      p={["40px 16px", null, "56px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
      background="#000000"
    >
      <Flex flexDirection="column" width={["100%", null, "1280px"]}>
        <Grid style={{ gap: "20px" }} gridTemplateColumns={["1fr", "1fr", "1fr 1fr", "repeat(8, minmax(0, 1fr))"]}>
          <Grid gridColumn={["span 1", "span 1", "span 2", "span 4"]}>
            <Flex flexDirection="column" mb={30}>
              <Text fontSize={20} fontWeight={500}>
                © 2024
              </Text>
              <Text fontSize={20} fontWeight={500}>
                Cybria Swap Labs
              </Text>
            </Flex>
            <Flex style={{ gap: "35px" }} alignItems="center" mt="auto">
              <Link href="https://twitter.com/cybriacoin" target="_blank">
                <TwitterIcon height={28} width={27} />
              </Link>
              <Link href="https://telegram.me/cybriacoin">
                <TelegramIcon height={28} width={25} />
              </Link>
              <Link href="https://github.com/cybriacoin" target="_blank">
                <GithubIcon height={28} width={28} />
              </Link>
            </Flex>
          </Grid>
          <Grid>
            <Flex flexDirection="column">
              <Text fontSize={17} fontWeight={500} mb={16}>
                App
              </Text>
              <StyledLink href="#" mb={"15px"}>
                Swap
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Tokens
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                NFTs
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Pool
              </StyledLink>
            </Flex>
          </Grid>
          <Grid>
            <Flex flexDirection="column">
              <Text fontSize={17} fontWeight={500} mb={16}>
                Protocol
              </Text>
              <StyledLink href="#" mb={"15px"}>
                Governance
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Developers
              </StyledLink>
            </Flex>
          </Grid>
          <Grid>
            <Flex flexDirection="column">
              <Text fontSize={17} fontWeight={500} mb={16}>
                Protocol
              </Text>
              <StyledLink href="#" mb={"15px"}>
                Careers
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Blog
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Brand Assets
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Terms & Privacy
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Trademark Policy
              </StyledLink>
            </Flex>
          </Grid>
          <Grid>
            <Flex flexDirection="column">
              <Text fontSize={17} fontWeight={500} mb={16}>
                Need help?
              </Text>
              <StyledLink href="#" mb={"15px"}>
                Contact us
              </StyledLink>
              <StyledLink href="#" mb={"15px"}>
                Help Center
              </StyledLink>
            </Flex>
          </Grid>
        </Grid>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
