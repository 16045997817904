import { cybaTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { CAKE_CYBA_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: cybaTokens.wcyba,
    token1: cybaTokens.cswap,
    lpAddress: '0x1033ab371D7370fcee478D9Ed422c301Fd321806',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 5,
    token0: cybaTokens.usdc,
    token1: cybaTokens.wcyba,
    lpAddress: '0x77ecB089992599aA5CA14375468527b605A756a5',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 6,
    token0: cybaTokens.usdt,
    token1: cybaTokens.wcyba,
    lpAddress: '0xd052A266a574bfa7aF1D6455A364F18CBaCcC3AA',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 7,
    token0: cybaTokens.usdt,
    token1: cybaTokens.cswap,
    lpAddress: '0x1e8AF005Cf613Ebb4FD016010B7674733bDd4081',
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'CSWAP',
    lpAddress: '0xD2a5576a70C1B43179a507e1eCd6B1fC90926Dea',
    token: cybaTokens.syrup,
    quoteToken: cybaTokens.wcyba,
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'CSWAP-CYBA LP',
    lpAddress: CAKE_CYBA_LP_MAINNET,
    token: cybaTokens.cswap,
    quoteToken: cybaTokens.wcyba,
    boosted: false,
  },
  {
    pid: 7,
    v1pid: 7,
    lpSymbol: 'CYBA-USDC LP',
    lpAddress: '0x910e84A263ecE5E8cf3F042140d2344EE1e7E637',
    token: cybaTokens.wcyba,
    quoteToken: cybaTokens.usdc,
    boosted: false,
  },
  {
    pid: 8,
    v1pid: 8,
    lpSymbol: 'CYBA-USDT LP',
    lpAddress: '0x1E4284b45D09e97394f25F99951E7591d8117001',
    token: cybaTokens.wcyba,
    quoteToken: cybaTokens.usdt,
    boosted: false,
  },
  {
    pid: 9,
    v1pid: 9,
    lpSymbol: 'CSWAP-USDT LP',
    lpAddress: '0xA206d90A8fc6b4F43E29fF4dDD6466570BCE5024',
    token: cybaTokens.cswap,
    quoteToken: cybaTokens.usdt,
    boosted: false,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export const farmsFinished = []

export default farms
