import useSWRImmutable from 'swr/immutable'

export const useCakePrice = () => {
  return useSWRImmutable(
    ['cake-usd-price'],
    async () => {
      const cswap = await (await fetch('https://www.cybaswap.io/api/farms/price/cake')).json()
      return cswap.price as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}
