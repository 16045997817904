import { languageList, useTranslation } from '@pancakeswap/localization'
import { footerLinks, Menu as UikitMenu, NextLinkFromReactRouter, useModal } from '@pancakeswap/uikit'
import USCitizenConfirmModal from 'components/Modal/USCitizenConfirmModal'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { usePhishingBanner } from '@pancakeswap/utils/user'
import { IdType } from 'hooks/useUserIsUsCitizenAcknowledgement'
import { useCakePrice } from '@pancakeswap/utils/useCakePrice'
import BigNumber from 'bignumber.js'
import Search from 'views/V3Info/components/Search'
import GlobalSettings from './GlobalSettings'
import { SettingsMode } from './GlobalSettings/types'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const LinkComponent = (linkProps) => {
  return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const Menu = (props) => {
  const { chainId } = useActiveChainId()
  const { isDark, setTheme } = useTheme()

  // price/cake
  const cakePriceUsd = useCakeBusdPrice()
  // const { data: dataPrice } = useCakePrice()
  // let cakePrice = new BigNumber(0)
  // if (dataPrice) {
  //   cakePrice = new BigNumber(dataPrice)
  // }
  // const cakePriceUsd = cakePrice

  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [onUSCitizenModalPresent] = useModal(
    <USCitizenConfirmModal title={t('CYBASWAP Swap Perpetuals')} id={IdType.PERPETUALS} />,
    false,
    false,
    'usCitizenConfirmModal',
  )
  const [showPhishingWarningBanner] = usePhishingBanner()

  const menuItems = useMenuItems(onUSCitizenModalPresent)

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const [linkBuy, setLinkBy] = useState('')
  useEffect(() => {
    if (chainId === 56) {
      setLinkBy(
        `${process.env.NEXT_PUBLIC_URL}/swap?outputCurrency=0x5C999E15B71de2bb8e651f0f999FB0Bc321a0DFe&chainId=56`,
      )
    }
    else if (chainId === 6661) {
      setLinkBy(
        `${process.env.NEXT_PUBLIC_URL}/swap?outputCurrency=0xD2a5576a70C1B43179a507e1eCd6B1fC90926Dea&chainId=6661`,
      )
    }
  }, [chainId])

  return (
    <>
      <UikitMenu
        linkComponent={LinkComponent}
        rightSide={
          <>
            <GlobalSettings mode={SettingsMode.GLOBAL} />
            <NetworkSwitcher />
            <UserMenu />
          </>
        }
        chainId={chainId}
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy CSWAP')}
        buyCakeLink={linkBuy}
        search={<Search styleInput={{ background: 'white', color: 'black', borderRadius: '22px', border: '1px solid #DCE4E5', paddingLeft: '40px' }} startIconInput={<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7" stroke="#969696" stroke-width="1.5" stroke-linecap="round" /><path d="M16 16L13 13" stroke="#969696" stroke-width="1.5" stroke-linecap="round" /></svg>} />}
        {...props}
      />
    </>
  )
}

export default Menu
