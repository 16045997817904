import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("ABOUT"),
    items: [
      {
        label: t("Contact"),
        href: "#",
      },
      {
        label: t("Brand"),
        href: "#",
      },
      {
        label: t("Blog"),
        href: "#",
      },
      {
        label: t("Community"),
        href: "#",
      },
      {
        label: t("CYBA Emission Projection"),
        href: "#",
      },
      {
        label: t("Terms Of Service"),
        href: "#",
      },
    ],
  },
  {
    label: t("Help"),
    items: [
      {
        label: t("Customer Support"),
        href: "#",
      },
      {
        label: t("Troubleshooting"),
        href: "#",
      },
      {
        label: t("Guides"),
        href: "#",
      },
    ],
  },
  {
    label: t("Developer"),
    items: [
      {
        label: "Github",
        href: "#",
      },
      {
        label: "Documentation",
        href: "#",
      },
      {
        label: "Bug Bounty",
        href: "#",
      },
      {
        label: "Audits",
        href: "#",
      },
      {
        label: "Careers",
        href: "#",
      },
    ],
  },
];
